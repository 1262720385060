import React from 'react';
import s from './Header.scss';
import {useControllerProps} from '../../ControllerContext';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {disableTranslationEscaping} from '../../../disableTranslationEscaping';

export enum HeaderDataHook {
  root = 'HeaderDataHook.root',
  title = 'HeaderDataHook.title',
  subtitle = 'HeaderDataHook.subtitle',
}

export function Header() {
  const {get} = useSettings();
  const {t} = useTranslation();
  const {buyerName, isSubscription} = useControllerProps().thankYouPageStore;

  const getSubscriptionTitle = () => {
    return (
      get(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_TITLE) ??
      t('THANK_YOU_PAGE_SUBSCRIPTION_TITLE', disableTranslationEscaping({fullName: buyerName}))
    );
  };

  const getOrderTitle = () => {
    const customTitle = get(settingsParams.THANK_YOU_PAGE_TITLE);
    if (customTitle) {
      return customTitle;
    }

    return buyerName
      ? t('thankYou.TITLE_WITH_NAME', disableTranslationEscaping({fullName: buyerName}))
      : t('thankYou.TITLE');
  };

  const getSubTitle = () => {
    const customSubTitle = isSubscription
      ? get(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_SUBTITLE)
      : get(settingsParams.THANK_YOU_PAGE_SUBTITLE);
    if (customSubTitle) {
      return customSubTitle;
    }
    return t('thankYou.SUBTITLE');
  };

  return (
    <header data-hook={HeaderDataHook.root} className={s.root}>
      <h1 className={s.title}>
        <span data-hook={HeaderDataHook.title}>{isSubscription ? getSubscriptionTitle() : getOrderTitle()}</span>
      </h1>

      <div className={s.subtitle} data-hook={HeaderDataHook.subtitle}>
        {getSubTitle()}
      </div>
    </header>
  );
}
