import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection} from '../OrderDetailsSection/OrderDetailsSection';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';
import s from './SubscriptionLabel.scss';

export enum SubscriptionLabelDataHook {
  root = 'SubscriptionLabelDataHook.root',
  subscriptionPlanLabel = 'SubscriptionLabelDataHook.subscriptionPlanLabel',
  itemName = 'SubscriptionLabelDataHook.itemName',
  subscriptionName = 'SubscriptionLabelDataHook.subscriptionName',
  subscriptionDuration = 'SubscriptionLabelDataHook.subscriptionDuration',
}

export function SubscriptionLabel() {
  const {get} = useSettings();
  const {t} = useTranslation();
  const {items, subscriptionName, subscriptionDuration, subscriptionFrequency} = useControllerProps().thankYouPageStore;

  const getSubsctiptionDuration = () => {
    if (!subscriptionDuration) {
      return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_AUTORENEW');
    }

    const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency, subscriptionDuration));
    if (subscriptionDuration === 1) {
      return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
        frequencyUnitSingular: frequencyUnit,
      });
    }

    return t('THANK_YOU_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
      numberOfFrequencyUnits: subscriptionDuration,
      frequencyUnitSingular: frequencyUnit,
    });
  };

  return (
    <OrderDetailsSection dataHook={SubscriptionLabelDataHook.root}>
      <OrderDetailsSection.Title dataHook={SubscriptionLabelDataHook.subscriptionPlanLabel}>
        {get(settingsParams.THANK_YOU_PAGE_SUBSCRIPTION_PLAN)}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content>
        <div data-hook={SubscriptionLabelDataHook.itemName}>{items[0].name}</div>
        <div data-hook={SubscriptionLabelDataHook.subscriptionName}>{subscriptionName}</div>
        <div data-hook={SubscriptionLabelDataHook.subscriptionDuration} className={s.duration}>
          {getSubsctiptionDuration()}
        </div>
      </OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
