import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection, OrderDetailsSectionLayout} from '../OrderDetailsSection/OrderDetailsSection';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import s from './StorePickup.scss';
import {disableTranslationEscaping} from '../../../disableTranslationEscaping';

export enum StorePickupDataHook {
  root = 'StorePickupDataHook.root',
  label = 'StorePickupDataHook.label',
  pickupAddress = 'StorePickupDataHook.pickupAddress',
  pickupTime = 'StorePickupDataHook.pickupTime',
  pickupInstructions = 'StorePickupDataHook.pickupInstructions',
}

export function StorePickup() {
  const {t} = useTranslation();
  const {address, instructions, time, title} = useControllerProps().thankYouPageStore.storePickup;

  return (
    <OrderDetailsSection dataHook={StorePickupDataHook.root} layout={OrderDetailsSectionLayout.Vertical}>
      <OrderDetailsSection.Title dataHook={StorePickupDataHook.label}>
        {title || t('thankYou.STORE_PICKUP_LABEL')}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content>
        <div data-hook={StorePickupDataHook.pickupAddress} className={s.pickupAddress}>
          {t('thankYou.DELIVERY_ADDRESS', disableTranslationEscaping(address))}
        </div>
        {time && (
          <div data-hook={StorePickupDataHook.pickupTime}>
            {t('thankYou.STORE_PICKUP_TIME', disableTranslationEscaping({deliveryTime: time}))}
          </div>
        )}
        <div data-hook={StorePickupDataHook.pickupInstructions}>{instructions}</div>
      </OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
