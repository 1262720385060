import React from 'react';
import s from './ThankYouPageApp.scss';
import {Header} from '../ComponentsDeprecated/Header/Header';
import {OrderLabel} from '../ComponentsDeprecated/OrderLabel/OrderLabel';
import {OrderPrice} from '../ComponentsDeprecated/OrderPrice/OrderPrice';
import {OfflinePayments} from '../ComponentsDeprecated/OfflinePayments/OfflinePayments';
import {useControllerProps} from '../ControllerContext';
import {ShippingInfo} from '../ComponentsDeprecated/ShippingInfo/ShippingInfo';
import {DigitalLinksError} from '../ComponentsDeprecated/DigitalLinksError/DigitalLinksError';
import {ContinueShopping} from '../ComponentsDeprecated/ContinueShopping/ContinueShopping';
import {StorePickup} from '../ComponentsDeprecated/StorePickup/StorePickup';
import {DigitalFiles} from '../ComponentsDeprecated/DigitalFiles/DigitalFiles';
import {SubscriptionLabel} from '../ComponentsDeprecated/SubscriptionLabel/SubscriptionLabel';

export function PageContent() {
  const {thankYouPageStore} = useControllerProps();

  const {
    shouldShowDigitalItems,
    shouldShowDigitalLinksError,
    shouldShowOfflinePayments,
    shouldShowShipping,
    shouldShowStorePickup,
    isSubscription,
    isValidOrder,
  } = thankYouPageStore;

  return (
    <>
      <Header />
      {shouldShowDigitalLinksError && <DigitalLinksError />}
      <div className={s.orderDetailsContainer}>
        <div className={s.section}>
          <ul>
            {isSubscription && (
              <li className={s.sectionItem}>
                <SubscriptionLabel />
              </li>
            )}
            {!isSubscription && isValidOrder && (
              <li className={s.sectionItem}>
                <OrderLabel />
              </li>
            )}
            <li className={s.sectionItem}>
              <OrderPrice />
            </li>
            {shouldShowOfflinePayments && (
              <li className={s.sectionItem}>
                <OfflinePayments />
              </li>
            )}
            {shouldShowStorePickup && (
              <li className={s.sectionItem}>
                <StorePickup />
              </li>
            )}
            {shouldShowShipping && (
              <li className={s.sectionItem}>
                <ShippingInfo />
              </li>
            )}
          </ul>
        </div>
        {shouldShowDigitalItems && (
          <div className={s.section}>
            <DigitalFiles />
          </div>
        )}
      </div>
      <ContinueShopping />
    </>
  );
}
