import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection} from '../OrderDetailsSection/OrderDetailsSection';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';
import {useTranslation} from '@wix/yoshi-flow-editor';

export enum OrderLabelDataHook {
  root = 'OrderLabelDataHook.root',
  orderNumberLabel = 'OrderLabelDataHook.orderNumberLabel',
  orderId = 'OrderLabelDataHook.orderId',
}

export function OrderLabel() {
  const {get} = useSettings();
  const {t} = useTranslation();
  const {orderId} = useControllerProps().thankYouPageStore;

  const getOrderNumberLabel = () => {
    const customTitle = get(settingsParams.THANK_YOU_PAGE_ORDER_NUMBER_LABEL);
    if (customTitle) {
      return customTitle;
    }

    return t('thankYou.ORDER_NUMBER_LABEL');
  };

  return (
    <OrderDetailsSection dataHook={OrderLabelDataHook.root}>
      <OrderDetailsSection.Title dataHook={OrderLabelDataHook.orderNumberLabel}>
        {getOrderNumberLabel()}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content dataHook={OrderLabelDataHook.orderId}>{orderId}</OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
