import React from 'react';
import {OrderDetailsSection, OrderDetailsSectionLayout} from '../OrderDetailsSection/OrderDetailsSection';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../ControllerContext';
import s from './OfflinePayments.scss';

export enum OfflinePaymentsDataHook {
  root = 'OfflinePaymentsDataHook.root',
  label = 'OfflinePaymentsDataHook.label',
  offlineInstruction = 'OfflinePaymentsDataHook.offlineInstruction',
}

export function OfflinePayments() {
  const {t} = useTranslation();
  const {offlineInstruction} = useControllerProps().thankYouPageStore;

  return (
    <OrderDetailsSection dataHook={OfflinePaymentsDataHook.root} layout={OrderDetailsSectionLayout.Vertical}>
      <OrderDetailsSection.Title dataHook={OfflinePaymentsDataHook.label}>
        {t('thankYou.OFFLINE_PAYMENT_LABEL')}
      </OrderDetailsSection.Title>
      <OrderDetailsSection.Content>
        <div
          className={s.offlineInstruction}
          data-hook={OfflinePaymentsDataHook.offlineInstruction}
          dangerouslySetInnerHTML={{__html: offlineInstruction}}
        />
      </OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
