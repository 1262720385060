import React from 'react';

export const DownloadIcon = (props: {className?: string}) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Group</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="-Thank-You-Page-/-320-/-digital" transform="translate(-32.000000, -660.000000)">
          <g id="Group" transform="translate(32.000000, 660.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <g id="Icons-/-Check-Copy" transform="translate(3.993700, 4.000000)">
              <path
                d="M1,11.9971 L1,14.4971 C1,14.7721 1.224,14.9971 1.5,14.9971 L1.5,14.9971 L13.5,14.9971 C13.776,14.9971 14,14.7721 14,14.4971 L14,14.4971 L14,11.9971 L15,11.9971 L15,14.4971 C15,15.3241 14.327,15.9971 13.5,15.9971 L13.5,15.9971 L1.5,15.9971 C0.672,15.9971 0,15.3241 0,14.4971 L0,14.4971 L0,11.9971 L1,11.9971 Z M7.9996,9.41469125e-14 L7.9996,10.078 L11.0356,7.043 L11.7426,7.75 L7.4996,11.992 L3.2576,7.75 L3.9646,7.043 L6.9996,10.078 L6.9996,9.41469125e-14 L7.9996,9.41469125e-14 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
