import React from 'react';
import s from './OrderDetailsSection.scss';

type Props = {
  dataHook?: string;
  children: React.ReactNode;
};

export enum OrderDetailsSectionLayout {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

type OrderDetailsSectionProps = Props & {
  layout?: OrderDetailsSectionLayout;
};

export function OrderDetailsSection({
  layout = OrderDetailsSectionLayout.Horizontal,
  dataHook,
  children,
}: OrderDetailsSectionProps) {
  const layoutClass = layout === OrderDetailsSectionLayout.Vertical ? s.vertical : s.horizontal;
  return (
    <div data-hook={dataHook} className={layoutClass}>
      {children}
    </div>
  );
}

const Title = ({dataHook, children}: Props) => {
  return (
    <div data-hook={dataHook} className={s.title}>
      {children}
    </div>
  );
};
OrderDetailsSection.Title = Title;

const Content = ({dataHook, children}: Props) => {
  return (
    <div data-hook={dataHook} className={s.content}>
      {children}
    </div>
  );
};
OrderDetailsSection.Content = Content;
